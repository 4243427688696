const Loading = (() => {
  const show = () => {
    $('div.loading-section').removeClass('hide');
  };

  const hide = () => {
    $('div.loading-section').addClass('hide');
  };

  return { show, hide };
})();

export default Loading;
