const Employee = (() => {
  const login = (input, password, organizationId) => {
    return $.ajax({
      type: 'POST',
      url: `/api/v1/employee/login`,
      data: {
        organizationId,
        input,
        password,
      },
    });
  };

  const logout = () => {
    return $.ajax({
      type: 'POST',
      url: `/api/v1/employee/logout`,
    });
  };

  const resetPassword = ({ organizationId, input }) => {
    return $.ajax({
      type: 'POST',
      url: `/api/v1/organization/${organizationId}/employee/${input}/reset-password`,
    });
  };

  const setPassword = ({
    organizationId,
    token,
    newPassword,
    confirmPassword,
  }) => {
    return $.ajax({
      type: 'POST',
      data: {
        organizationId,
        newPassword,
        confirmPassword,
      },
      url: `/api/v1/employee/set-password?token=${token}`,
    });
  };

  return { login, logout, resetPassword, setPassword };
})();

export default Employee;
