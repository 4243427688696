const pendingFonts = [];
let isInitalizedWebfont = false;

const getGoogleFont = fontName => {
  if (fontName && fontName !== 'adelle-sans') {
    // google webfont is not ready
    if (!window.WebFont) {
      pendingFonts.push(fontName);

      if (!isInitalizedWebfont) {
        loadGoogleFontsLib();
      }

      return;
    }

    window.WebFont.load({
      google: { families: [fontName + '::latin'] },
    });
  }
};

const onGoogleWebFontready = () => {
  let fontName = pendingFonts.shift();

  while (fontName) {
    window.WebFont.load({
      google: { families: [fontName + '::latin'] },
    });

    fontName = pendingFonts.shift();
  }
};

const loadGoogleFontsLib = () => {
  isInitalizedWebfont = true;

  var wf = document.createElement('script');
  wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js';
  wf.type = 'text/javascript';
  wf.async = 'true';
  wf.onload = onGoogleWebFontready;
  var s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(wf, s);
};

export default {
  getGoogleFont,
};
