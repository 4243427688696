import Employee from 'client/scripts/data/Employee';
import Loading from 'client/scripts/common/loading';
import utils from 'client/scripts/common/utils';
import fontLoader from 'client/scripts/common/fontLoader';
import { EmployeeCode } from 'client/scripts/common/businessCodes';

let organizationId;

function parserHexToGrbg() {
  let splashColor = $('.employee-handbook-header .light-blue-bg').attr(
    'data-color'
  );

  if (splashColor) {
    $('.employee-handbook-header .light-blue-bg').css(
      'background-color',
      utils.hexToRGBA(splashColor) || ''
    );
  }
}

function showMessageResetPassword() {
  $('#employee-reset-pw').prop('disabled', true);
  $('#resetPassword').hide();
  $('.reset-pw-success').show();
}

function resetPassword() {
  $('.reset-pw-success').hide();
  $('#send-email-error')
    .text('')
    .hide();
  const inputResetPw = $('#employee-reset-pw')
    .val()
    .trim();

  if (!inputResetPw) {
    $('#send-email-error')
      .text('Email is not empty')
      .show();
    return;
  }

  Loading.show();

  Employee.resetPassword({
    organizationId,
    input: inputResetPw,
  })
    .done(data => {
      if (data && data.code === EmployeeCode.ResetPassword.ReActivate) {
        $('#employee-reset-pw').prop('disabled', true);
        $('#resetPassword').hide();
        $('#send-email-error')
          .text(
            'Your account is not verified, please check your email to verify!'
          )
          .show();
      } else {
        showMessageResetPassword();
      }
      Loading.hide();
    })
    .fail(error => {
      const errorMsg =
        error && error.responseText
          ? error.responseText
          : 'Employee is not found';

      $('#send-email-error')
        .text(errorMsg)
        .show();
      Loading.hide();
    });
}

function eventListeners() {
  const empLoginBtn = $('#employeeLoginBtn');
  const $loginForm = $('#employee-login-form');
  const $resetForm = $('#reset-password-form');
  const $securityForm = $('#security-password-warning-form');
  const $loginError = $('#login-error');
  const $portalLoginWrapper = $('.portal-login-wrapper');

  if (typeof empLoginBtn != 'undefined' && empLoginBtn != null) {
    empLoginBtn.on('click', function(e) {
      e.preventDefault();

      $loginError.text('').hide();

      const input = $('#employeeUser')
        .val()
        .trim();
      const password = $('#employeePassword').val();

      if (!password || !input) {
        $loginError.text('Email or Password is not empty').show();
        return;
      }

      Loading.show();
      Employee.login(input, password, organizationId)
        .done(data => {
          if (data) {
            location.reload();
          }
        })
        .fail(error => {
          if (error.status === 401) {
            // password is not secure
            $portalLoginWrapper.hide();
            $securityForm.show();
          }
          const errorMsg =
            error && error.responseText
              ? error.responseText
              : 'Email or password incorrect. Try again...';

          $loginError.text(errorMsg).show();
        })
        .always(() => {
          Loading.hide();
        });
    });
  }

  $('#employeePassword').keypress(function(e) {
    var key = e.which;
    if (key == 13) {
      // the enter key code
      $('#employeeLoginBtn').click();
    }
  });

  $('#employee-login-form .text-reset-password').on('click', function(e) {
    e.preventDefault();
    $loginForm.hide();
    $resetForm.show();

    $('#employee-reset-pw')
      .val('')
      .prop('disabled', false);
    $('.reset-pw-success, #send-email-error').hide();
    $('button#resetPassword').show();
  });

  $('#reset-password-form #back-to-login').on('click', function(e) {
    e.preventDefault();
    $loginForm.show();
    $resetForm.hide();
  });

  $('#resetPassword').on('click', function(e) {
    e.preventDefault();
    resetPassword();
  });

  $('#employee-reset-pw').keypress(function(e) {
    var key = e.which;
    if (key == 13) {
      // the enter key code
      e.preventDefault();
      resetPassword();
    }
  });
}

function applyFonts() {
  const $title = $(
    '.portal-login-container .portal-login-wrapper .title-login'
  );
  const titleFontName = $title.data('font-slug');

  if (titleFontName) {
    fontLoader.getGoogleFont(titleFontName);
    $title.css({ 'font-family': `"${titleFontName}", Arial, sans-serif` });
  }

  const $ssoMessage = $(
    '.portal-login-container .message-sso-force, .portal-login-container .message-login'
  );
  const messageFontName = $ssoMessage.data('font-slug');

  if (messageFontName) {
    fontLoader.getGoogleFont(messageFontName);
    $ssoMessage.css({
      'font-family': `"${messageFontName}", Arial, sans-serif`,
    });
  }
}

const employeeLogin = (() => {
  const init = () => {
    const organization = HandbookViewer.organization;
    organizationId = organization.id;

    eventListeners();
    parserHexToGrbg();

    // load google fonts
    applyFonts();
  };

  return { init };
})();

export default employeeLogin;
