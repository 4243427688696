const urlParam = function(name) {
  var results = new RegExp('[?&]' + name + '=([^&#]*)').exec(
    window.location.search
  );

  return results !== null ? results[1] || 0 : false;
};

function addCSS(id, style) {
  let styleSheet = document.getElementById(id);

  if (!styleSheet) {
    styleSheet = document.createElement('style');
    styleSheet.type = 'text/css';
    styleSheet.id = id;
    document.head.appendChild(styleSheet);
  }

  styleSheet.innerText = style;
}

function isPathAbsolute(path) {
  return /^(?:\/|[a-z]+:\/\/)/.test(path);
}

const getBaseUrl = () => {
  return `${location.origin}${
    location.pathname !== '/' ? location.pathname : ''
  }`;
};

const hexToRGBA = hex => {
  if (!hex) {
    return '';
  }

  hex = hex.replace(/[^0-9a-fA-F]/g, '');

  if (hex.length < 5) {
    hex = hex
      .split('')
      .map(s => s + s)
      .join('');
  }

  let rgba = hex.match(/.{1,2}/g).map(s => parseInt(s, 16));
  rgba[3] = rgba.length > 3 ? parseFloat(rgba[3] / 255).toFixed(2) : 1;

  return 'rgba(' + rgba.join(', ') + ')';
};

const orderValuesForSectionValues = () => {
  $('.block.values').each(function() {
    const $block = $(this);

    $block.find('.value').each(function(index) {
      const number = index + 1;
      $(this)
        .attr('data-value-id', number)
        .find('span.number')
        .text(number);
    });
  });
};

const getOrganizationURL = organization => {
  return organization.customDomain &&
    organization.isUsedCustomDomain &&
    organization.isCustomDomainWorking
    ? `https://${organization.customDomain}`
    : `/${organization.slug}`;
};

function hexToRgbaOpacity(hex, opacity) {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(_, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const rgba = result
    ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16
      )}, ${opacity})`
    : null;

  return rgba;
}

function getValuesFromLinearGradient(linearGradient) {
  if (!linearGradient) {
    return {};
  }

  const values = linearGradient
    .slice(0, -1)
    .substr(16)
    .split(',');

  return {
    degStr: values[0].trim(),
    deg: parseInt(values[0].slice(0, -3)),
    colorStop1: values[1].trim(),
    colorStop2: values[2].trim(),
  };
}

function isSectionApplyCollapsible(sectionType) {
  const TemplateCollapsible = [
    'blank',
    'values',
    'hero',
    'bios',
    'newspaper',
    'table_grid',
    'key_points',
  ];
  return TemplateCollapsible.includes(sectionType);
}

export default {
  urlParam,
  addCSS,
  isPathAbsolute,
  getBaseUrl,
  hexToRGBA,
  orderValuesForSectionValues,
  getOrganizationURL,
  hexToRgbaOpacity,
  getValuesFromLinearGradient,
  isSectionApplyCollapsible,
};
