import utils from 'client/scripts/common/utils';
import Employee from '../../scripts/data/Employee';
import Loading from 'client/scripts/common/loading';

const regexPassword = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%?&*])[A-Za-z@$!%?&*\d]{14,}$/;

function initButtonSetPwd() {
  const newPassword = $('#newPassword').val();
  const confirmPassword = $('#confirmNewPassword').val();

  if (!newPassword || !confirmPassword) {
    $('#setNewPassword').addClass('disabled');
  } else {
    $('#setNewPassword').removeClass('disabled');
  }
}

const setPassword = (() => {
  const init = () => {
    const organization = HandbookViewer.organization;
    const organizationId = organization.id;
    const organizationUrl = utils.getOrganizationURL(organization);
    const token = utils.urlParam('token');

    const $inputNewPasswordForm = $(
      '#set-new-password-form .input-control-new-password'
    );
    const $warningNewConfirmPwd = $(
      '#set-new-password-form .warning-new-confirm-password'
    );

    $('#setNewPassword').on('click', function(e) {
      e.preventDefault();

      $warningNewConfirmPwd.hide();
      $inputNewPasswordForm.removeClass('error');
      $('#reset-password-error')
        .text('')
        .hide();

      const newPassword = $('#newPassword').val();
      const confirmPassword = $('#confirmNewPassword').val();

      if (!regexPassword.test(newPassword)) {
        $inputNewPasswordForm.addClass('error');
        return;
      }

      if (newPassword != confirmPassword) {
        $warningNewConfirmPwd
          .text('Passwords do not match! please try again')
          .show();
        return;
      }

      Loading.show();

      const data = {
        newPassword,
        confirmPassword,
        organizationId,
        token,
      };

      Employee.setPassword(data)
        .done(_data => {
          window.location = organizationUrl;
        })
        .fail(error => {
          console.log(error);
          const errorMsg =
            error && error.responseText
              ? error.responseText
              : 'An error occurred. Please try again later.';
          $('#reset-password-error')
            .text(errorMsg)
            .show();
        })
        .always(() => {
          Loading.hide();
        });
    });

    $('#set-new-password-form #back-to-login').on('click', function(e) {
      window.location = organizationUrl;
    });

    $('#newPassword, #confirmNewPassword').on('keyup', function(e) {
      initButtonSetPwd();
    });

    $('#newPassword').on('keyup', function(e) {
      $inputNewPasswordForm.removeClass('error');
    });
  };

  return { init };
})();

export default setPassword;
