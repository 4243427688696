import Employee from '../../scripts/data/Employee';
import Loading from 'client/scripts/common/loading';

const employeeLogout = (() => {
  const init = () => {
    $('#logout-btn').on('click', function(e) {
      e.preventDefault();
      Loading.show();
      Employee.logout()
        .done(data => {
          if (data === 'OK') {
            Loading.hide();
            location.reload();
          }
        })
        .fail(error => {
          Loading.hide();
          console.log('Logout error: ', JSON.stringify(error));
        });
    });
  };

  return { init };
})();

export default employeeLogout;
